.terminal-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: calc(100vh - 133px); /* Adjust if necessary to fit your header/footer */
    background-image: url('./images/terminal-background2.png');
    background-size: cover;
    background-position: center;
    color: limegreen;
    font-family: monospace;
    padding: 10px; /* Reduced padding might allow for more content */
    overflow: hidden;
}

.messages-container {
    overflow-y: auto;
    margin-bottom: 10px; /* Space between messages and input */
    max-height: calc(100% - 50px); /* Adjusted to leave space for input, adjust based on your input height */
}
  
  .output {
    max-height: 100%;
    overflow-y: auto;
    white-space: pre-wrap;
  }
  
  .terminal-input {
    margin-top: 20px; /* Adds space between messages and input */
    background-color: #222;
    color: #0f0;
    padding: 10px;
    border: none;
    outline: none; /* Removes focus outline */
    width: calc(100% - 20px); /* Adjusts width to fit container padding */
}
  
  .terminal-line {
    white-space: pre-wrap; /* CSS property to preserve whitespace and wrap text */
  }

  .terminal-line, .output-line {
    white-space: pre-wrap; /* Preserves formatting and enables wrapping */
    padding-left: 0ch;
}