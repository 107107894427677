/* src/Home.css */
.home-container {
    /* This will scope your Home component styles, so they don't spill over */
  }
  
  .hero-section {
    background-size: cover;
    background-position: center;
    padding: 100px 0; /* Adjust padding to fit your design */
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  
  .hero-title {
    font-family: 'Neuropol', sans-serif; /* Replace with your font */
    color: #ff3a5b; /* Base color for text */
    font-size: 4rem;
    text-transform: uppercase;
    text-align: center;
    line-height: 1.2;
    letter-spacing: 2px;
    text-shadow: 
      0 0 7px #fbafff,
      0 0 10px #fbafff,
      0 0 21px #fbafff, /* Magenta glow */
      0 0 42px #21d4fd,
      0 0 82px #21d4fd,
      0 0 92px #21d4fd,
      0 0 102px #21d4fd,
      0 0 151px #21d4fd; /* Blue glow */
    
    /* Optional: Create a gradient overlay for the text for a more dynamic effect */
    background: linear-gradient(45deg, #ff3a5b, #ffc700, #38ff00, #00c1ff, #f720c5);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    
    /* An animated glow effect */
    animation: glow 1s ease-in-out infinite alternate;
  }
  
  /* Glow animation keyframes */
  @keyframes glow {
    from {
      text-shadow: 
        0 0 4px #fbafff, 
        0 0 10px #fbafff, 
        0 0 18px #fbafff, 
        0 0 25px #21d4fd, 
        0 0 35px #21d4fd, 
        0 0 45px #21d4fd, 
        0 0 55px #21d4fd, 
        0 0 70px #21d4fd;
    }
    to {
      text-shadow: 
        0 0 6px #fbafff, 
        0 0 12px #fbafff, 
        0 0 24px #fbafff, 
        0 0 30px #21d4fd, 
        0 0 40px #21d4fd, 
        0 0 50px #21d4fd, 
        0 0 60px #21d4fd, 
        0 0 75px #21d4fd;
    }
  }

/* Apply globally if all text across the site should have this style */
.text-content p, /* or just use .text-content if all its children will inherit the style */
.text-lg {
  font-family: 'Roboto Mono', monospace; /* Techy, readable font */
  color: #ff7597; /* A soft pink for readability and style */
  font-size: 1rem; /* Adjust the size for readability */
  line-height: 1.6; /* Improve line height for readability */
  text-shadow: 
    0 0 5px #ff7597, /* Soft glow for the text */
    0 0 10px #ff7597; /* Slightly larger glow effect */
  background-color: rgba(0, 0, 0, 0.7); /* Slight dark background for text legibility */
  padding: 1rem; /* Padding around the text */
  border-radius: 5px; /* Optional: rounded corners for the text background */
  margin: 1rem 0; /* Space above and below the text */
  overflow-wrap: break-word; /* Prevents long strings from overflowing */
}

/* If the content is not directly inside .text-content, specify the class or ID */
.someSpecificClass {
  /* Styles specific to other texts */
}

/* Consider adding media queries for responsiveness */
@media (max-width: 768px) {
  .text-lg {
    font-size: 0.875rem; /* Slightly smaller font on mobile for better fit */
    line-height: 1.4;
    padding: 0.5rem; /* Less padding on mobile */
  }
}

/* Styles for the CoT logo container */
.cot-logo-container {
  display: flex; /* Use flexbox for easy centering */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically if needed */
  margin-top: 2rem; /* Add some space above the logo */
}

.cot-logo {
  max-width: 100%; /* Ensure it is responsive and fits its container */
  height: auto; /* Maintain aspect ratio */
  /* Add additional styles if you want to control the size further */
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .manifesto .text-image-container {
    flex-direction: column;
  }

  .manifesto-image, .text-content {
    width: 100%;
    order: unset;
    margin-bottom: 1rem;
  }
}

.affils-container {
  width: 100%;
  overflow: hidden; /* Ensures that the scrolling content is clipped to the container */
  white-space: nowrap; /* Keeps the affils in a single line */
}

.affils-scrolling {
  display: inline-block;
  padding-left: 100%; /* Start the animation fully off-screen to the right */
  animation: scrollAffils 20s linear infinite; /* Adjust the timing as needed */
}

.affils-scrolling span {
  margin-right: 2rem; /* Space between each affil */
  color: #3399ff; /* Example color, adjust as needed */
  text-shadow: 0 0 5px #ff7597, 0 0 10px #ff7597; /* Optional: Add a glow effect */
}

@keyframes scrollAffils {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%); /* Moves the content fully off-screen to the left */
  }
}
