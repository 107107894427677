/* Footer.css */

.footer {
    /*background-color: #000022; /* Dark purple background to match header */
    color: #e002ec; /* Neon green text */
    text-align: center;
    padding: 1rem 0;
  }
  
  .footer-background {
    background-image: url('./images/footer2.png'); /* Make sure the path is correct */
    background-size: cover; /* Cover the entire area */
    background-position: center; /* Center the background image */
    background-repeat: no-repeat; /* Do not repeat the image */
    padding: 1rem 0; /* Adjust padding as necessary */
    color: #f3eaf3; /* Neon green text */
    text-align: center;
    /* Add any other styles you need for your footer */
  }