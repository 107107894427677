@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css');

/* General Styles */
html, body {
  height: 100%;
  margin: 0;
  padding: 0; /* Reset padding to prevent overflow */
  overflow-x: hidden; /* Hide horizontal overflow */
  font-family: 'Fira Code', 'Courier New', monospace;
  background-color: #000000; /* Deep blue/black background */
  color: #33ff33; /* Neon green text */
}

.container {
  width: 100%;
  max-width: 1200px;
  padding: 0 15px;
  margin: 0 auto;
}

.flex {
  display: flex;
}

.justify-between {
  justify-content: space-between;
}

.items-center {
  align-items: center;
}

/* Text Styles */
.text-xl {
  font-size: 1.25rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-green-400 {
  color: #3f3; /* Lighter green for text */
}

.text-green-500, .success {
  color: #00FF00; /* Pure green for accents and success messages */
}

.text-blue-400 {
  color: #39f; /* Bright blue */
}

/* Navigation Bar Styles */
.nav-bar {
  display: flex;
  justify-content: center; /* Center buttons horizontally */
  align-items: center; /* Align buttons vertically */
  padding: 1rem 0; /* Padding around the entire nav bar */
  flex-wrap: wrap; /* Ensure nav items wrap and don't overflow */
  background-color: #000022; /* Dark purple background */
}

/* Updated Navigation Button Styles */
.nav-button {
  color: #3399ff; /* Adjust color as needed */
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  backdrop-filter: blur(5px); /* Blur effect */
  border: 2px solid #3399ff; /* Neon blue border for contrast */
  font-family: 'Roboto Mono', monospace; /* Techy, readable font */
  font-size: 1rem; /* Adjust the size for readability */
  padding: 10px 20px; /* Adjust padding to fit your design */
  border-radius: 20px; /* Rounded corners for a modern look */
  transition: all 0.3s ease-in-out; /* Smooth transition for hover effects */
  margin: 0 10px; /* Space between buttons */
}

/* Assuming you might have specific styles for the first button */
.nav-button:first-child {
  margin-right: 0; /* Removing margin to the right of the first button */
}

.nav-button span {
  display: block;
  transform: skewX(15deg); /* Counter-skew text to normalize it */
}

.nav-button:hover, .nav-button:focus {
  color: #ff33ff; /* Neon pink for hovering and focus */
  background-color: #0055AA; /* Lighter blue on hover/focus */
  border-color: #ff33ff; /* Border color changes on hover/focus */
  box-shadow: 0 0 8px #ff33ff, 0 0 10px #ff33ff; /* Glow effect on hover/focus */
}

/* Navigation styles */
.nav {
  display: flex;
  justify-content: space-around; /* Evenly space navigation links */
  width: 100%; /* Take up the full width for better spacing */
}

/* Header and Footer Styles */
.sticky-header {
  position: sticky;
  top: 0;
  z-index: 100;
  width: 100%; /* Ensure header spans the full width */
  background-color: #000022; /* Match the nav bar background */
}

.header-image {
  max-height: 100px; /* Adjust as needed */
  width: auto; /* Adjust width automatically based on the height */
}

.header-background {
  background-size: cover; /* Cover the entire area of the header */
  background-position: center; /* Center the background image */
  background-repeat: no-repeat; /* Do not repeat the image */
}

.header-title {
  font-family: 'Fira Code', monospace; /* Terminal font */
  color: #ff33ff; /* Neon pink */
  text-shadow:
    3px 3px 0 #000, /* Horizontal shadow, Vertical shadow, Magenta glow */
    -1px -1px 0 #000, /* Carved effect */
    1px -1px 0 #000,
    -1px 1px 0 #000,
    1px 1px 0 #000;
  font-size: 2.5rem; /* Adjust size as necessary */
  letter-spacing: 0.1em; /* Spacing for synthwave style */
}

.cot-logo {
  height: 50px; /* Adjust the height to fit the header */
  width: auto; /* Maintain aspect ratio */
  margin-right: 20px; /* Space between the logo and the nav buttons */
  margin-top: 10px; /* Bring the logo down from the top edge */
}


/* Main Content Styles */
.app-content {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main-content {
  flex: 1;
  overflow-y: auto;
  padding: 1rem 20px;
}

/* Hide scrollbar for Chrome, Safari, and Opera */
::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge, and Firefox */
body {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}


.scroll-indicator {
  position: fixed;
  bottom: 20px;
  right: 20px;
  font-size: 2rem;
  color: #ff00ff; /* Neon magenta, adjust as fits your theme */
  animation: blink 2s infinite;
  z-index: 100; /* Ensure it's above other content */
}

@keyframes blink {
  0%, 100% { opacity: 1; }
  50% { opacity: 0.5; }
}

/* Link Styles */
a {
  text-decoration: none; /* Optional: removes underline from links */
}

a:hover {
  color: #ff33ff; /* Neon pink for hovering over links */
}

/* Button Styles */
button {
  background-color: #0066ff; /* Neon blue */
  color: #ffffff; /* White text */
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #ff3399; /* Neon pink on hover */
}

/* Message Styles */
.error {
  color: #ff3333; /* Bright red for errors */
}

.warning {
  color: #ff9933; /* Bright orange for warnings */
}

.success {
  color: #33ff33; /* Bright green for success messages */
}

/* Utility Classes */
.whitespace-pre-wrap {
  white-space: pre-wrap; /* Allows your text to wrap and preserve formatting */
}

.border-b {
  border-bottom-style: solid;
}

.border-green-500 {
  border-color: #00ff00; /* Bright green */
}

/* Responsive Design Tweaks */
@media (max-width: 768px) {
  .nav-button {
    margin: 0.5rem 0; /* Space out buttons vertically */
    width: auto; /* Adjust width to content */
  }

  .nav-bar {
    justify-content: space-around; /* Distribute buttons evenly on smaller screens */
  }
}