/* src/components/MouseTrail.css */
.pointer-effect {
    position: absolute;
    color: #0f0; /* Neon green, a staple of cyberpunk visuals */
    user-select: none;
    pointer-events: none;
    font-family: 'Courier New', Courier, monospace; /* Monospaced font for the digital look */
    opacity: 0.8;
    animation: fadeOut 0.8s forwards; /* Animation to fade out and slightly move */
    font-weight: bold;
  }
  
  @keyframes fadeOut {
    to {
      opacity: 0;
      transform: translateY(-20px); /* Make the characters float up as they fade */
    }
  }
  